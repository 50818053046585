import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Tooltip from '@mui/material/Tooltip';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { UPLOAD_COMPANY_BENEFICIARIES } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';

const UploadLegalBeneficiaries = () => {
  const [files, setFiles] = useState([{ name: '' }]);
  const [open, toggleOpen] = useBooleanState();
  const { addAlert } = useSnackBars();

  const handleClose = () => {
    toggleOpen(false);
    setFiles([{ name: '' }]);
  };
  const [uploadMutation, { loading }] = useMutation(UPLOAD_COMPANY_BENEFICIARIES, {
    variables: { file: files[0] },
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'delete-control-group',
      message: err.message,
    }),
  });

  return (
    <>
      <Tooltip title="Cargar beneficiarios con archivo .xlsx de Regcheq">
        <CloudUploadIcon
          color="primary"
          onClick={toggleOpen}
          sx={{ cursor: 'pointer' }}
        />
      </Tooltip>
      <FingoDialog
        title="Subir archivo .xslx"
        open={open}
        handleClose={handleClose}
        dialogActionButton={(
          <LoadingButton
            key="upload-bank-statement-button"
            size="small"
            color="primary"
            variant="contained"
            onClick={uploadMutation}
            loading={loading}
            disabled={files[0].name === ''}
          >
            Subir archivo
          </LoadingButton>
        )}
      >
        <UploadDropZoneDialogContainer
          key="upload-bank-statement"
          files={files}
          setFiles={setFiles}
        />
      </FingoDialog>
    </>
  );
};

export default UploadLegalBeneficiaries;
