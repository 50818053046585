import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { GET_LEGAL_MASTER_ENTITIES } from '@fingo/lib/graphql';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import legalColumns from '../../constants/legal-columns';
import UploadLegalBeneficiaries from '../UploadLegalBeneficiaries';

const LegalInformation = () => {
  const [openRows, setOpenRows] = useState(false);

  const toggleRowProfile = (id) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <FingoMainView
      id="legal"
      query={GET_LEGAL_MASTER_ENTITIES}
      slots={{
        header: PaperHeader,
        actions: UploadLegalBeneficiaries,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: 'Legal',
          finder: {
            searchPlaceHolder: 'Buscar empresa',
          },
        },
        table: {
          columns: legalColumns(openRows, toggleRowProfile),
          initialOrderBy: 'name',
          noRowsMessage: () => <Typography>No hay clientes</Typography>,
          rowsPerPageOptions: [10, 25, 50, 100],
        },
      }}
    />
  );
};
export default LegalInformation;
