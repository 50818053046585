import React from 'react';
import Typography from '@mui/material/Typography';
import { CustomerDocumentsCell } from '@fingo/lib/components/cells';
import UpdateCompanyInformationDialog from '@fingo/lib/components/dialogs/UpdateCompanyInformationDialog';
import FileStatusCell from '../../../components/cells/FileStatusCell';

const baseColumnConfig = {
  sortable: false,
  flex: 1,
  align: 'center',
  headerAlign: 'center',
};

const getInitialDocumentTypes = (isNaturalPersonRut) => (isNaturalPersonRut
  ? ['client_file_uaf', 'funds_back_up']
  : ['client_file_uaf', 'legal_report', 'funds_back_up']);

const legalColumns = (openRows, toggleRowProfile) => [
  {
    field: 'client',
    headerName: 'Cliente',
    type: 'string',
    minWidth: 210,
    sortable: true,
    flex: 2,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <>
        <Typography
          variant="body2"
          fontWeight="bold"
          color="primary"
          onClick={() => toggleRowProfile(row.id)}
          sx={{ cursor: 'pointer' }}
        >
          {row.name || ''}
        </Typography>
        <UpdateCompanyInformationDialog
          masterEntityId={row.id}
          open={!!openRows[row.id]}
          setOpen={() => toggleRowProfile(row.id)}
          isNaturalPersonRut={row.isNaturalPersonRut}
        />
      </>
    ),
  },
  {
    ...baseColumnConfig,
    field: 'rut',
    headerName: 'Rut',
    renderCell: ({ row }) => (
      <Typography variant="body2">{row.displayNationalIdentifier}</Typography>
    ),
  },
  {
    ...baseColumnConfig,
    field: 'nationality',
    headerName: 'Nacionalidad',
    renderCell: () => <Typography variant="body2">Chilena</Typography>,
  },
  {
    ...baseColumnConfig,
    field: 'client-type',
    headerName: 'Tipo de cliente',
    renderCell: ({ row }) => (
      <Typography variant="body2">
        {row.isNaturalPersonRut ? 'Natural' : 'Jurídica'}
      </Typography>
    ),
  },
  {
    ...baseColumnConfig,
    field: 'legal-documents',
    headerName: 'Documentos para descargar',
    flex: 2,
    renderCell: ({ row }) => (
      <CustomerDocumentsCell
        masterEntity={row}
        wrap
        initialDocumentTypes={getInitialDocumentTypes(row.isNaturalPersonRut)}
      />
    ),
  },
  {
    ...baseColumnConfig,
    field: 'file-status',
    headerName: 'Estado de la ficha',
    renderCell: ({ row }) => (
      <FileStatusCell
        isCompleted={row.hasAllLegalInformation}
      />
    ),
  },
];

export default legalColumns;
